<script lang="ts">
    import Alert from "./alert.svelte";
    import Alert2 from "./alert2.svelte";
    import PencilFilled from "./pencil_filled.svelte";
    import Plus from "./plus.svelte";
    import DropArrowDown from "./dropArrowDown.svelte";
    import Chat from "./chat.svelte";
    import Logout from "./logout.svelte";
    import Home from "./home.svelte";
    import Users from "./users.svelte";
    import Order from "./order.svelte";
    import Statement from "./statement.svelte";
    import Calendar from "./calendar.svelte";
    import Checkbox from "./checkbox.svelte";
    import Question from "./question.svelte";
    import Percent from "./percent.svelte";
    import Website from "./website.svelte";
    import Pen from "./pen.svelte";
    import Download from "./download.svelte";
    import FileDownload from "./fileDownload.svelte";
    import FileInvoice from "./fileInvoice.svelte";
    import Cart from "./cart.svelte";
    import Tool from "./tool.svelte";
    import ArrowUp from "./arrowUp.svelte";
    import Search from "./search.svelte";
    import Checkout from "./checkout.svelte";
    import Information from "./information.svelte";
    import AddUser from "./addUser.svelte";
    import Identity from "./identity.svelte";
    import Email from "./email.svelte";
    import Calendar2 from "./calendar2.svelte";
    import List from "./list.svelte";
    import Card from "./card.svelte";
    import Phone from "./phone.svelte";
    import User from "./user.svelte";
    import Location from "./location.svelte";
    import ArrowLeft from "./arrowLeft.svelte";
    import Error from "./error.svelte";
    import Close from "./close.svelte";
    import Close2 from "./close2.svelte";
    import RekkiInfo from "./rekkiInfo.svelte";
    import RekkiClose from "./rekkiClose.svelte";
    import GroupedOrder from "./groupedOrder.svelte";
    import Account from "./account.svelte";
    import Gear from "./gear.svelte";
    import Copy from "./copy.svelte";
    import Cross from "./cross.svelte";
    import ErrorInfo from "./errorinfo.svelte";
    import Replay from "./replay.svelte";
    import DotsVertical from "./dotsVertical.svelte";
    import Edit from "./edit.svelte";
    import EditFull from "./edit-full.svelte";
    import Check from "./check.svelte";
    import CheckCircle from "./checkCircle.svelte";
    import CheckCircle2 from "./checkCircle2.svelte";
    import CrossCircle from "./crossCircle.svelte";
    import Time from "./time.svelte";
    import Sliders from "./sliders.svelte";
    import MerchantPortal from "./merchantPortal.svelte";
    import WebShop from "./webShop.svelte";
    import WarningInfo from "./warningInfo.svelte";
    import ErrorIcon from "./erroricon.svelte";
    import ErrorCircle from "./errorCircle.svelte";
    import File from "./file.svelte";
    import TwoLogo from "./twoLogo.svelte";
    import Envelope from "./envelope.svelte";
    import TimeAlt from "./time-alt.svelte";
    import NoEdit from "./noedit.svelte";
    import Delete from "./delete.svelte";
    import Add from "./add.svelte";
    import ArrowRightCircle from "./arrowRightCircle.svelte";
    import Wallet from "./wallet.svelte";
    import Plans from "./plans.svelte";
    import ChevronLeft from "./chevron-left.svelte";

    export let name: string = "alert";
    export let fill: string = null;
    export let size: number = 16;
    export let makeSquare: boolean = false;
    export let stroke: string = null;
</script>

{#if name === "alert"}
    <Alert {fill} {size} />
{:else if name === "alert-2"}
    <Alert2 {fill} {size} />
{:else if name === "pencil-filled"}
    <PencilFilled {fill} />
{:else if name === "plus"}
    <Plus {fill} />
{:else if name === "drop-arrow-down"}
    <DropArrowDown {fill} {size} {makeSquare} />
{:else if name === "chat"}
    <Chat {fill} />
{:else if name === "logout"}
    <Logout {fill} {size} />
{:else if name === "home"}
    <Home {fill} {size} />
{:else if name === "users"}
    <Users {fill} {size} />
{:else if name === "order"}
    <Order {fill} {size} />
{:else if name === "statement"}
    <Statement {fill} {size} />
{:else if name === "calendar"}
    <Calendar {fill} {size} />
{:else if name === "checkbox"}
    <Checkbox {fill} {size} />
{:else if name === "question"}
    <Question {fill} {size} />
{:else if name === "percent"}
    <Percent {fill} />
{:else if name === "website"}
    <Website {fill} {size} />
{:else if name === "pen"}
    <Pen {fill} {size} />
{:else if name === "download"}
    <Download {fill} {size} />
{:else if name === "file-download"}
    <FileDownload {fill} {size} />
{:else if name === "file-invoice"}
    <FileInvoice {fill} {size} />
{:else if name === "cart"}
    <Cart />
{:else if name === "tool"}
    <Tool {fill} {size} />
{:else if name === "arrow-up"}
    <ArrowUp {fill} />
{:else if name === "search"}
    <Search {fill} {size} />
{:else if name === "checkout"}
    <Checkout {fill} />
{:else if name === "information"}
    <Information {fill} {size} />
{:else if name === "add-user"}
    <AddUser {fill} {size} />
{:else if name === "identity"}
    <Identity {fill} {size} />
{:else if name === "email"}
    <Email {fill} {size} />
{:else if name === "calendar2"}
    <Calendar2 {fill} {size} />
{:else if name === "list"}
    <List {fill} {size} />
{:else if name === "card"}
    <Card {fill} {size} />
{:else if name === "phone"}
    <Phone {fill} {size} />
{:else if name === "user"}
    <User {fill} {size} />
{:else if name === "location"}
    <Location {fill} {size} />
{:else if name === "arrow-right-circle"}
    <ArrowRightCircle {fill} {size} />
{:else if name === "arrow-left"}
    <ArrowLeft {fill} {size} />
{:else if name === "chevron-left"}
    <ChevronLeft {fill} {size} />
{:else if name === "error"}
    <Error {fill} {size} />
{:else if name === "close"}
    <Close {fill} {size} />
{:else if name === "close2"}
    <Close2 {fill} {size} />
{:else if name === "rekkiinfo"}
    <RekkiInfo {fill} {size} />
{:else if name === "rekkiclose"}
    <RekkiClose {fill} {size} />
{:else if name === "groupedOrder"}
    <GroupedOrder {fill} {size} />
{:else if name === "account"}
    <Account {fill} {size} />
{:else if name === "gear"}
    <Gear {fill} {size} />
{:else if name === "copy"}
    <Copy {fill} {size} />
{:else if name === "cross"}
    <Cross {fill} {size} />
{:else if name === "errorinfo"}
    <ErrorInfo {fill} {size} />
{:else if name === "replay"}
    <Replay {size} />
{:else if name === "dotsvertical"}
    <DotsVertical {size} />
{:else if name === "edit"}
    <Edit {fill} {size} />
{:else if name === "check"}
    <Check />
{:else if name === "check-circle"}
    <CheckCircle {fill} {size} />
{:else if name === "check-circle-2"}
    <CheckCircle2 {fill} {size} />
{:else if name === "edit-full"}
    <EditFull {fill} {size} />
{:else if name === "cross-circle"}
    <CrossCircle {fill} {size} />
{:else if name === "time"}
    <Time {fill} {size} />
{:else if name === "time-alt"}
    <TimeAlt {fill} />
{:else if name === "sliders"}
    <Sliders {size} />
{:else if name === "merchant-portal"}
    <MerchantPortal {size} />
{:else if name === "webshop"}
    <WebShop {size} />
{:else if name === "warningInfo"}
    <WarningInfo {size} {fill} />
{:else if name === "erroricon"}
    <ErrorIcon {size} />
{:else if name === "errorCircle"}
    <ErrorCircle {size} {fill} />
{:else if name === "file"}
    <File {size} {fill} />
{:else if name === "logo"}
    <TwoLogo {size} />
{:else if name === "envelope"}
    <Envelope {size} />
{:else if name === "noedit"}
    <NoEdit {size} />
{:else if name === "delete"}
    <Delete {size} {fill} />
{:else if name === "add"}
    <Add {size} {fill} />
{:else if name === "wallet"}
    <Wallet {size} {fill} {stroke} />
{:else if name === "plans"}
    <Plans {size} {fill} />
{/if}
